import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Conquer To-Do App" />
    <div id="title">
      <h1>
        <a href="https://apps.apple.com/gb/app/conquer-to-do/id1453562222">
          Download Conquer App
          <div className="strike" />
        </a>
      </h1>
    </div>
  </Layout>
)

export default IndexPage
